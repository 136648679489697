<template>
  <div class="search-page">
    <form class="form-search" id="initial_search">
      <div>
        <input
          class="search-input"
          type="search"
          v-model="currentRefinement"
          :placeholder="getSearchPlaceholder()"
          @input="refine($event.currentTarget.value)"
          @focus="onFocusSearchInput($event, currentRefinement)"
          @blur="onBlurSearchInput(currentRefinement)"
        />
        <span :hidden="!isSearchStalled">Loading...</span>
      </div>
      <a href="#" class="form-close"
        ><img
          class="desktop_form_close"
          src="@/assets/img/close.svg"
          alt="close"
      /></a>
    </form>

    <div class="note"><strong>Just begin typing . . .</strong></div>

    <div>
      <div class="search-tab-menu">
        <a
          @click.prevent="selectedTab = 'ARTIST'"
          :class="{ active: selectedTab === 'ARTIST' }"
          href="#artists"
          >Artists</a
        >
        <a
          @click.prevent="selectedTab = 'TITLE'"
          :class="{ active: selectedTab === 'TITLE' }"
          href="#titles"
          >Titles</a
        >
      </div>

      <div class="search-results" v-show="currentRefinement">
        <div
          v-show="showArtistResults"
          class="search-item item search-results-artists"
          id="tab2"
        >
          <h4 class="search-item-title">Artists</h4>
          <div
            v-for="item in getUniqueArtists(artists.hits)"
            v-bind:key="item.objectID"
          >
            <router-link
              class="search-result-link"
              v-bind:to="'/artist/' + item.artist_name"
            >
              <Highlight
                :text="item.artist_name"
                :highlight="currentRefinement"
              />
            </router-link>
          </div>
        </div>
        <div
          v-show="showTitleResults"
          class="search-item item active search-results-titles"
          id="tab1"
        >
          <h4 class="search-item-title">Titles</h4>
          <div
            v-for="item in getUniqueTitles(titles.hits)"
            v-bind:key="item.objectID"
          >
            <router-link
              class="search-result-link"
              v-bind:to="'/title/' + item.title"
            >
              <Highlight :text="item.title" :highlight="currentRefinement" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { resizeComputed } from "@/services/resizeService";
import { searchClient, songsIndex } from "@/services/algoliaService";
import Highlight from "@/components/common/Highlight";
export default {
  components: {
    Highlight,
  },
  data() {
    return {
      selectedTab: "ARTIST",
      currentRefinement: "",
      artists: [],
      titles: [],
      isSearchStalled: false,
    };
  },
  created() {
    this.refine("");
  },
  computed: {
    ...resizeComputed,
    isMobile() {
      return this.screen.width < 768;
    },
    showTitleResults() {
      if (!this.isMobile) return true;
      return this.selectedTab === "TITLE";
    },
    showArtistResults() {
      if (!this.isMobile) return true;
      return this.selectedTab === "ARTIST";
    },
  },
  watch: {
    "screen.width"(width) {
      console.log("width", width);
      if (width > 767 && this.$options.searchBoxTranslated) {
        this.resetPageYPosition();
      }
    },
  },
  methods: {
    async refine(searchTerm = "") {
      const queries = [
        {
          indexName: "songs-prod2",
          query: searchTerm,
          params: {
            restrictSearchableAttributes: ["artist_name"],
            attributesToRetrieve: ["artist_name", "artist_id"],
            distinct: true,
            hitsPerPage: 5,
          },
        },
        {
          indexName: "songs-prod2",
          query: searchTerm,
          params: {
            restrictSearchableAttributes: ["title"],
            attributesToRetrieve: ["title", "id", "artist_id", "artist_name"],
            distinct: true,
            hitsPerPage: 5,
          },
        },
      ];
      const { results } = await searchClient.multipleQueries(queries);
      console.log("multiqueries", results);
      const [artists, titles] = results;
      this.artists = artists;
      this.titles = titles;

      // this.result = results.reduce(
      //   (acc, data) => {
      //     acc.hits = [...acc.hits, ...data.hits];
      //     acc.nbHits += data.nbHits;
      //     acc.nbPages += data.nbPages;
      //     return acc;
      //   },
      //   {
      //     hits: [],
      //     nbHits: 0,
      //     hitsPerPage: 0,
      //     nbPages: 0,
      //   }
      // );
      console.log({ result: this.result });
    },
    onFocusSearchInput(e) {
      if (this.screen.width > 767) return;
      if (this.$options.searchBoxTranslated) return;
      console.log("on focus", e, e.target.getBoundingClientRect());
      const { top: offsetTop } = e.target.getBoundingClientRect();
      this.$el.style.transform = `translateY(-${offsetTop - 40}px)`;
      this.$options.searchBoxTranslated = true;
    },
    onBlurSearchInput(query) {
      console.log("query", query);
      if (this.screen.width > 767) return;
      if (query) return;
      this.resetPageYPosition();
    },
    resetPageYPosition() {
      this.$el.style.transform = "translateY(0px)";
      this.$options.searchBoxTranslated = false;
    },
    getSearchPlaceholder() {
      const nbHits = 98198785;
      return this.screen.width < 768
        ? `${nbHits.toLocaleString()} tracks`
        : `Search ${nbHits.toLocaleString()} songs ...`;
    },
    getUniqueArtists(hits = []) {
      let included = {};
      let items = [];
      for (const item of hits) {
        if (!Reflect.has(included, item.artist_name)) {
          included[item.artist_name] = true;
          item.legal_name = Object.prototype.hasOwnProperty.call(
            item,
            "legal_name"
          )
            ? item.legal_name
            : item.artist_name;
          items.push(item);
        }
      }

      return items;
    },
    getUniqueTitles(hits = []) {
      let included = {};
      let items = [];
      for (const item of hits) {
        if (!Reflect.has(included, item.title)) {
          included[item.title] = true;
          items.push(item);
        }
      }
      return items;
    },
  },
};
</script>
<style lang="scss" scoped>
.search-input {
  padding-left: 0;
  -webkit-appearance: textfield;

  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  &::placeholder {
    color: $color-grey-light;
  }
}
.search-page {
  transition: 0.5s transform;
  background-color: $color-background;
  ::v-deep {
    .ais-Highlight-highlighted {
      color: $color-text;
      font-weight: 600;
      background-color: transparent;
    }
  }

  .search-result-link {
    font-family: "helveticaneue";
    font-weight: 400;
  }
}
.search-tab-menu a {
  text-decoration: none;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  font-size: 22px;
  color: #cad1db;
  font-weight: 500;
}

.search-tab-menu a.active {
  color: #bd7eee;
}

.search-tab-menu a:last-child {
  margin-right: 0;
}

.search-tab-menu {
  margin: 30px 0;
  display: block;
  @include tablet {
    display: none;
  }
}

.search-results {
  margin-top: 80px;
  display: flex;

  &-titles {
    @include tablet {
      margin-right: 0.5rem;
    }
  }
  &-artists {
    @include tablet {
      margin-left: 0.5rem;
    }
  }
}

.search-results .item {
  // float: left;
  width: 50%;
}

.search-results .item a {
  color: #373a3c;
  font-size: 22px;
  line-height: 44px;
  letter-spacing: 1px;
  display: block;
  text-decoration: none;
}

.search-results .item h4 {
  color: #bd7eee;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: 2px;
  margin: 0 0 15px;
}

@media only screen and (max-width: 767px) {
  h4.search-item-title {
    display: none;
  }

  .search-results .item.active {
    display: block;
  }

  .search-results .item {
    width: 100%;
    float: none;
  }

  .search-results {
    margin-top: 0;
  }
}

@media (max-width: 1070px) {
  .search-results .item h4 {
    font-size: 18px;
  }

  .search-results .item a {
    font-size: 16px;
  }
}
</style>