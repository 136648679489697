<template>
  <span v-html="content"> </span>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    highlight: {
      type: String,
      required: true,
    },
  },
  computed: {
    content() {
      return this.text.replaceAll(this.highlight, `<b>${this.highlight}</b>`);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>